import IOrderModel, { OrderModel } from "@models/finance/order.model";
import { makeAutoObservable } from "mobx";
import { RootStore } from "@stores/index";
import { PagedResultModel } from "@/src/models/paged-result.model";

class OrderStore {
  isLoading: boolean = false;
  showDrawer?: boolean = false;
  pagedResult: PagedResultModel<IOrderModel> = {
    results: [],
    totalCount: 0,
  };
  editOrder: OrderModel = new OrderModel();

  constructor(root: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async initOrder(order?: any) {
    this.editOrder = order || new OrderModel();
  }

  async updateOrder(order: any) {
    this.isLoading = true;
  }

  async addProduct(product: any, quantity: number) {
    if (this.editOrder.orderDetails.some((x) => x.productId === product.id)) {
      this.editOrder.orderDetails.forEach((x) => {
        if (x.productId === product.id) {
          const updateQuantity = x.quantity + quantity;
          if (updateQuantity > product.remaining) {
            return;
          }
          x.quantity += quantity;
          x.amount = x.quantity * x.unitPrice;
        }
      });
    } else {
      this.editOrder.orderDetails.push({
        productId: product.id,
        product: product,
        quantity: quantity,
        unitPrice: product.price,
        amount: quantity * product.price,
      });
    }
    this.initOrder({ ...this.editOrder });
  }
}
export default OrderStore;
