export class OptionModel {
  id?: number;
  value: any;
  label?: string;
  code?: string;
  icon?: string;
  name?: string;

  constructor(
    value?: string | undefined,
    label?: string | undefined,
    id?: number | undefined,
    code?: string | undefined,
  ) {
    this.id = id;
    this.value = value;
    this.label = label;
    this.code = code;
  }

  public static assign(obj: any) {
    if (!obj) return undefined;

    let newObj = Object.assign(new OptionModel(), obj);
    newObj.label = obj.label || obj.name || obj.displayName;
    newObj.value = obj.value || obj.id || obj.code;
    return newObj;
  }

  public static assigns<T>(objs: any[] = []) {
    let results: any[] = [];
    objs.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}

export interface ISystemModel {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}
